import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload, Flex } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../ServiceList.css";
import Loading from "../../../components/Loading/LoadingEffect";
import { commonList } from '../../../controllers/common';
import { createStore } from "../../../controllers/services/dogFriendly"
import { list as vendorlist } from "../../../controllers/vendors/vendorsController"
import { beforeUpload } from '../../../controllers/common';

const ProductListAddEditDog = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
  const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setPhotosList("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration: 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg = "") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration: 2,
      icon: <CloseOutlined style={{ color: "red" }} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(false);
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState("");
  const [imageList, setImageList] = useState("");
  const [photos, setPhotosList] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);

  /*********************************************************
    *  This function is use to get all vendor list
    *********************************************************/
  const getVendorList = async () => {
    try {
      const options = {
        condition: { status: "A" },
        select: {
          vendor_id: false,
          vendor_email: false,
          creation_date: false,
          createdAt: false,
          creation_ip: false,
          vendor_address: false,
          vendor_city: false,
          vendor_country: false,
          vendor_image: false,
          vendor_otp: false,
          vendor_phone: false,
          vendor_pincode: false,
          vendor_state: false,
          created_by: false,
          update_date: false,
          update_ip: false,
          updatedAt: false,
          updated_by: false,
          vendor_commission: false,
          vendor_country_code: false,
          vendor_gender: false,
          vendor_revenue: false,
          vendor_street: false
        },
        sort: { vendor_first_name: 1 },
        skip: 0,
        limit: 0
      }
      const res = await vendorlist(options);
      if (res.status === true) {
        setAllVendor(res?.result)
      } else {
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  const getCommonListData = async (vendorId) => {
    try {
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      setAllSubCategory([]);
      const options = {
        vendorId: vendorId,
        list: ["service_category", "store", "branch"],
        serviceCat: { select: { name: true } },
        serviceSubCat: { select: { category: true, name: true } },
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } }
      }
      const res = await commonList(options);
      if (res?.status === true) {
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        setAllSubCategory(res?.result?.serviceSubCat);
        console.log('editdata', editdata);
        if (editdata?.branch) {
          const filterArray = res?.result?.branch.filter(val => val.storeData == editdata?.store?._id);
          setFilterBranch(filterArray);
        }
        // if (editdata.subcategory) {
        //   const filterArray = res?.result?.serviceSubCat.filter(val => val.category == editdata?.category?._id)
        //   setFilterSubCat(filterArray);
        // }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (name === 'parks') {
      setFormData((pre) => ({
        ...pre,
        [name]: value
      }));
      // alert(value)
      getCommonListData(value);
      setFilterBranch([]);
      setFilterSubCat([]);
    } else if (name === 'restaurant') {
      setFormData((pre) => ({
        ...pre,
        [name]: value
      }));
      const filterArray = ALLBRANCH.filter(val => val.storeData == value)
      setFilterBranch(filterArray);
      setFilterSubCat([]);
    } else if (name === 'category') {
      setFormData((pre) => ({
        ...pre,
        [name]: value
      }));
      const filterArray = ALLSUBCATEGORY.filter(val => val.category == value)
      setFilterSubCat(filterArray);
    } else {
      setFormData((pre) => ({
        ...pre,
        [name]: value
      }))
      setEditData((pre) => ({
        ...pre,
        [name]: value
      }))

    }
    serError((pre) => ({
      ...pre,
      [e.target.name]: ""
    }))
  }; //End

  const handleTypeClick = (name) => {
    try {
      setFormData((pre) => ({
        ...pre,
        [name]: formData[name] === "Y" ? "N" : "Y",
      }));
      setEditData((pre) => ({
        ...pre,
        [name]: formData[name] === "Y" ? "N" : "Y",
      }));

      serError((preError) => ({
        ...preError,
        type: ""
      }));
    } catch (error) {

    }
  }

  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
    setPhotosList(e.fileList)
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    form.append("image", imageList ? imageList : null);

    if (photos) {
      photos.forEach((item, index) => {
        form.append(`photos`, item.originFileObj);
      });
    }
    form.append("parks", formData?.parks || 'N');
    form.append("restaurant", formData?.restaurant || 'N');
    form.append("cafe", formData?.cafe || 'N');
    form.append("beach", formData?.beach || 'N');
    form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
    const isValidate = validateFormData(form)
    if (isValidate) {
      const res = await createStore(form);
      console.log("createStore res", res);
      if (res.status === true) {
        setFormData("");
        setImageList("");
        setPhotosList("");
        handleSuccessMsg();
        handleCancel();
      } else {
        serError((pre) => ({
          ...pre,
          formError: res?.message
        }))
        handleErrorMsg();
        // setFormData("");
        // setImageList("");
        // handleCancel();
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleErrorMsg('Please fill required fields.');
    }
  } //End
  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    if (formData.get('parks') === 'undefined' || formData.get('parks') === null || formData.get('parks').trim() === '') {
      serError((preError) => ({
        ...preError,
        // vendor: "Vendor is required"
      }));
      return false;
    } else if (formData.get('restaurant') === 'undefined' || formData.get('restaurant') === null || formData.get('restaurant').trim() === '') {
      serError((preError) => ({
        ...preError,
        // store: "Store is required"
      }));
      return false;
    } else if (formData.get('cafe') === 'undefined' || formData.get('cafe') === null || formData.get('cafe').trim() === '') {
      serError((preError) => ({
        ...preError,
        // branch: "Branch is required"
      }));
      return false;
    } else if (formData.get('beach') === 'undefined' || formData.get('beach') === null || formData.get('beach').trim() === '') {
      serError((preError) => ({
        ...preError,
        // category: "Category is required"
      }));
      return false;
    } else if (formData.get('center_facilities') === 'undefined' || formData.get('center_facilities') === null || formData.get('center_facilities').trim() === '') {
      serError((preError) => ({
        ...preError,
        center_facilities: "Center Facilities is required."
      }));
      return false;
    } else if (!editdata?._id && formData.get('image') === 'null') {
      serError((preError) => ({
        ...preError,
        image: "Image is required"
      }));
      return false;
    } else {
      return true;
    }
  }; //End

  useEffect(() => {
    getVendorList();
    if (editdata?._id) {
      setIsLoading(true);
      getCommonListData(editdata?.vendor);
      if (Array.isArray(editdata?.center_facilities)) {
        setEditData((pre) => ({
          ...pre,
          center_facilities: editdata.center_facilities.join(', ')
        }))
      }
    }
  }, [show]);

  return (
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id ? "Friendly" : "Add Friendly"} Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="editId" id="editId" value={editdata?._id} />
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="name" className="categories_open_popup_label_name"> Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        placeholder="Enter title name"
                        name="title"
                        value={formData?.title ? formData?.title : editdata?.title}
                        onChange={handleChnage}
                      />
                      {error?.title ? <p style={{ color: "red" }}>{error.title}</p> : ""}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="name" className="categories_open_popup_label_name"> About</label>
                      <input
                        type="text"
                        class="form-control"
                        id="about"
                        placeholder="Enter about"
                        name="about"
                        value={formData?.about ? formData?.about : editdata?.about}
                        onChange={handleChnage}
                      />
                      {error?.about ? <p style={{ color: "red" }}>{error.about}</p> : ""}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="ratings" className="categories_open_popup_label_name">
                        Ratings
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="ratings"
                        placeholder="Enter ratings (0-5)"
                        name="ratings"
                        min="0"
                        max="5"
                        step="0.5"
                        value={formData?.ratings ? formData.ratings : editdata?.ratings}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 5) {
                            handleChnage(e);
                          } else if (e.target.value > 5) {
                            e.target.value = 5;
                            handleChnage(e);
                          }
                        }}
                      />


                      {error?.ratings ? <p style={{ color: "red" }}>{error.ratings}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="name" className="categories_open_popup_label_name"> Latitude</label>
                      <input
                        type="number"
                        class="form-control"
                        id="latitude"
                        placeholder="Enter latitude"
                        name="latitude"
                        value={formData?.latitude ? formData?.latitude : editdata?.latitude}
                        onChange={handleChnage}
                      />
                      {error?.latitude ? <p style={{ color: "red" }}>{error.latitude}</p> : ""}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="name" className="categories_open_popup_label_name"> Longitude</label>
                      <input
                        type="number"
                        className="form-control"
                        id="longitude"
                        placeholder="Enter longitude"
                        name="longitude"
                        value={formData?.longitude ? formData?.longitude : editdata?.longitude}
                        onChange={handleChnage}
                      />
                      {error?.longitude ? <p style={{ color: "red" }}>{error.longitude}</p> : ""}
                    </div>
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="categories_open_popup_label_name">
                        <span style={{ color: "red" }}>*</span>Search Type :
                      </label>

                      <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="parks" id="parks" size="large" >
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('parks')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="parks">Parks</label>
                              <input
                                type="radio"
                                id="parks"
                                name="parks"
                                value="parks"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.parks === 'Y' ? true : false || editdata.parks === 'Y' ? true : false}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('restaurant')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="type_mobile">Restaurant</label>
                              <input
                                type="radio"
                                id="restaurant"
                                name="restaurant"
                                value="restaurant"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.restaurant === 'Y' ? true : false || editdata.restaurant === 'Y' ? true : false}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('cafe')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="pick_drop">Cafe</label>
                              <input
                                type="radio"
                                id="cafe"
                                name="cafe"
                                value="cafe"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.cafe === 'Y' ? true : false || editdata.cafe === 'Y' ? true : false}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('beach')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="beach">Beach</label>
                              <input
                                type="radio"
                                id="beach"
                                name="beach"
                                value="beach"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.beach === 'Y' ? true : false || editdata.beach === 'Y' ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {error?.type ? <p style={{ color: "red" }}>{error.type}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="center_facilities" className="categories_open_popup_label_name"> Center Facilities</label>
                      <textarea
                        className="form-control"
                        id="center_facilities"
                        name="center_facilities"
                      >{editdata?.center_facilities}</textarea>

                      {error?.center_facilities ? <p style={{ color: "red" }}>{error.center_facilities}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="categories_open_popup_label_name" >Image</label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }}>*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                      {error?.image ? <p style={{ color: "red" }}>{error.image}</p> : ""}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="categories_open_popup_label_name" >Photos Gallery</label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          name="photos"
                          multiple={true}
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                          beforeUpload={beforeUpload}
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }} className="all_top_and_bottom_for_input_text">
                        *Note: Please choose an image of JPEG/JPG/PNG format file.
                      </p>
                      {error?.photos ? <p style={{ color: "red" }}>{error.photos}</p> : ""}
                    </div>



                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    // onClick={handleSubmit}
                    >
                      {editdata._id ? "Save Changes" : 'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          <Loading
            isLoading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProductListAddEditDog;
