import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { InfoCircleOutlined, CheckCircleOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar"
import UserListDeletePopUp from "./UserListDeletePopUp";
import { Link, useNavigate } from "react-router-dom";
import "./UserList.css";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { list, chnageStatus } from "../../../controllers/users/usersControllet";
import { getPage, statusMessage } from '../../../controllers/common';

function UserList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

UserList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const [testListlen, setTestListlen] = useState(0);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId]= useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End
  const [showRequest, setShowRequest] = useState("");


  const handleEditUser = (item) =>{
    navigate('/users/addeditdata', {state : item});
  }

  /*********************************************************
  *  This function is use to fetch user list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    setIsLoading(true);
    const options = {
      type : "",
      condition : { 
        ...(showRequest?{status : showRequest}:null)
      },
      ...(filter?{filter:filter}:null),
      select    : {},
      // sort      : {"_id" : -1},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    console.log('users listData', listData);
    if(listData.status === true){
      if(listData.result.length > 0){
        setTestListlen(listData.result.length);
        setListData(listData?.result);
        setIsLoading(false);
      } else{
        setTestListlen(listData.result.length);
        setListData([]);
        setIsLoading(false);
        // setListData([{name : 'No Data Found'}]);  
      }
      const countOptions = {
        type : "count",
        condition : {
          ...(showRequest?{status : showRequest}:null) 
        },
        ...(filter?{filter:filter}:null)
      }
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result)); 
      setIsLoading(false);
    }else{
      setIsLoading(false);
      // setListData([{name : 'No Data Found'}]);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /*********************************************************
  *  This function is use to handle remove users
  *********************************************************/
  const handleStatusChnage = async (status='', id) => {
    try{
      setListData([]);
      const options ={
        url : `accounts/users/changestatus`,
        postData :{
          user_id : id,
          status : status,
        }
      }
      const res = await chnageStatus(options);
      if(res.status === true){
        notification.open({
          message: "Data updated.",
          description:  `Data updated Successfully.`,
          placement: "topRight", 
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration:2
        });
      } else{
        notification.open({
          message: "Opps!",
          description:  `${res?.message}`,
          placement: "topRight", 
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          duration:2
        });
      }
    } catch (error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    getList();
  }

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    })
    document.title = 'Frisbee Super Admin || User List'
  },[currentPage,showRequest, popUpOpen, filter]);


  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`User's List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={()=>setShowRequest('')}
                    />
                    <Tab
                      label="Deleted Users"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={()=>setShowRequest('D')}
                    />
                    <Tab
                      label="Inactive Users"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={()=>setShowRequest('I')}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <UserList>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        User List
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Account Creation</th>
                              <th>Contact No.</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (
                            ALLLISTDATA.map((items, index) => (
                              <>
                              <tr key={index} onDoubleClick={()=>handleEditUser(items)}>
                                <td>
                                  <div className="admin_user_list_table">
                                    <div>
                                      {items?.image?(
                                        <img src={`${ASSETS_BASE_URL}${items.image}`} alt={items.image} width="50px"/>
                                      ):(
                                        <img src={`../images/IntentoryTableLogo.png`} alt={`profile-pic`} width="30px"/>
                                      )}
                                    </div>
                                    <div className="admin_user_list_name_gmail">
                                      <span className="admin_user_list_name">
                                        {items?.name?items.name:"New User"}
                                      </span>
                                      <span className="admin_user_list_gmail">
                                      {items?.email?items.email:"..."}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="admin_user_list_date">
                                {moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A')}{" "}
                                </td>
                                <td className="admin_user_list_date">
                                  {items?.country_code} {items?.phone}
                                </td>
                                <td className="admin_user_list_date" dangerouslySetInnerHTML={{__html : statusMessage(items.status)}}></td>
  
                                <td>
                                  {items.status !== 'D' &&(
                                    <Dropdown
                                    overlay={
                                        <Menu>
                                          <Menu.Item key={`${index}01`} onClick={()=>handleEditUser(items)}>
                                              <FaEdit />
                                              <span className="show_span_edit">Edit</span>
                                          </Menu.Item>
                                          {items?.status==='I' && (
                                            <Menu.Item key={`${index}02`} onClick={()=>{ handleStatusChnage('A', items._id) }}>
                                              <LikeOutlined /> <span className="show_span">Active</span>
                                            </Menu.Item>  
                                          )}
                                          {items?.status==='A' && (
                                            <Menu.Item key={`${index}02`} onClick={()=>{ handleStatusChnage('I', items._id) }}>
                                              <DislikeOutlined /> <span className="show_span">Inactive</span>
                                            </Menu.Item>  
                                          )}
                                          <Menu.Item key={`${index}03`} onClick={()=>{
                                            setDeleteId(items._id)
                                            togglePopUp()
                                            }}>
                                            <MdDelete /> <span className="show_span">Delete</span>
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      placement="bottomLeft"
                                      arrow
                                    >
                                      <HiOutlineDotsHorizontal />
                                    </Dropdown>
                                  )}
                                </td>
                              </tr>
                              </>
                            ))
                          ) : isLoading ? <SkeltonList row={10} col={5} /> : <tr> 
                                <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </UserList>
            {/* Second Inventory */}
            {/* <UserList value={value} index={1}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        New Users
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Account Creation</th>
                              <th>Contact No.</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                            <tr key={index}>
                              <td>
                                <div className="admin_user_list_table">
                                  <div>
                                    {items?.image?(
                                      <img src={`${ASSETS_BASE_URL}${items.image}`} alt={items.image} width="50px"/>
                                    ):(
                                      <img src={`../images/IntentoryTableLogo.png`} alt={`profile-pic`} width="30px"/>
                                    )}
                                  </div>
                                  <div className="admin_user_list_name_gmail">
                                    <span className="admin_user_list_name">
                                      {items?.name?items.name:"New User"}
                                    </span>
                                    <span className="admin_user_list_gmail">
                                    {items?.email?items.email:"..."}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="admin_user_list_date">
                              {moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A')}{" "}
                              </td>
                              <td className="admin_user_list_date">
                                {items?.country_code} {items?.phone}
                              </td>
                              <td className="admin_user_list_date" dangerouslySetInnerHTML={{__html : statusMessage(items.status)}}></td>
                              <td>
                                <HiOutlineDotsHorizontal />
                              </td>
                            </tr>
                          </>))) : isLoading ? <SkeltonList row={10} col={5} /> : <tr> 
                                <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </UserList> */}
            {/* Third Inventory */}
          </Box>
        </div>
        <UserListDeletePopUp
         popUpOpen={popUpOpen} 
         togglePopUp={togglePopUp} 
         deleteId ={deleteId}
         setDeleteId={setDeleteId}
         handleStatusChnage={handleStatusChnage}
        />

        {/* Filter */}
        <Drawer
          title="User Filter"
          placement={`right`}
          width={500}
          onClose={handleFiterDrawer}
          open={isFilterShow}
          extra={
            <Space>
              <Button onClick={handleFiterDrawer}>Cancel</Button>
              <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
                Reset
              </Button>
            </Space>
          }
        >
          <div className="responsive">
            <div className="row">
              <form id='filter_form' onSubmit={handleFilterApply}>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    Search Field :
                  </label>
                  <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                    <option value=''>Select search field</option>
                    <option value='name' selected={filter?.filter_by ==='name'?true:false}>User Name</option>
                    <option value='phone' selected={filter?.filter_by ==='phone'?true:false}>User Phone</option>
                    <option value='email' selected={filter?.filter_by ==='email'?true:false}>User Email</option>
                    <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                  </select>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    Search Text :
                  </label>
                  <input
                    title="Enter search text."
                    placeholder="Enter search text."
                    type="text"
                    name="search"
                    className="form-control store_input_field"
                    id="search"  
                    ref={inputRef2}
                    // onChange={handleFilterChnage} 
                    // value={filter?.search}             
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    From :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="from_date"
                    className="form-control store_input_field"
                    id="from_date"      
                    ref={inputRef3}
                    // onChange={handleFilterChnage}          
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    To :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="to_date"
                    className="form-control store_input_field"
                    id="to_date"   
                    ref={inputRef4}
                    // onChange={handleFilterChnage}             
                  />
                </div>
                <br/>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button
                    title="Enter search text."
                    type="submit"
                    className="form-control btn btn-black "    
                    style={{ backgroundColor: "black", color: "white" }}           
                  >Apply</button>
                </div>
              </form>
            </div>
          </div>
        </Drawer>

      </div>
    </>
  );
}
