import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  addeditdata,
  chnageStatus,
} from "../../../controllers/users/usersControllet";
import { fetchIpAddress } from "../../../controllers/API";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Dragger } = Upload;
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, serError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [image, setImage] = useState([]);

  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddEditSata((pre) => ({
      ...pre,
      [name]: value,
    }));

    serError((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  // if(name === 'email'){
  //  if(!validateEmail(value)){
  //   serError((preError) => ({
  //  ...preError,
  //  email: "Invalid email format" 
  //   }));
  //  }else{
  //   serError((preError) => ({
  //     ...preError,
  //     email: "",
  //   }));
  //  }
  // }

  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };

  /*********************************************************
   *  This function is use to handle genger checkbox chnage
   *********************************************************/
  const onCheckedChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      gender: e.target.value,
    }));
  };


  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async () => {
    try {
      const { _id, name, email, phone, gender } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();
      const form = new FormData();
      form.append("user_id", _id);
      form.append("name", name);
      form.append("email", email);
      form.append("phone", phone);
      form.append("gender", gender ? gender : "Male");
      form.append("ipAddress", ipAddress);
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }
      const isValidate = validateFormData(form);
      console.log("form", form);
      if (isValidate) {
        const options = {
          url: "accounts/users/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/users/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^[0-9]+$/;
    if (
      formData.get("name") === "undefined" ||
      formData.get("name") === null ||
      formData.get("name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        name: "Name is required",
      }));
      return false;
    } else if (
      formData.get("phone") === "undefined" ||
      formData.get("phone") === null ||
      formData.get("phone").trim() === "" ||
      !numberRegex.test(formData.get("phone"))
    ) {
      if (formData.get("phone").trim() === "") {
        serError((preError) => ({
          ...preError,
          phone: "Phone number is required",
        }));
      } else {
        serError((preError) => ({
          ...preError,
          phone: "Enter a valid mobile number",
        }));
      }
      return false;
    } else if (
      formData.get("email") === "undefined" ||
      formData.get("email") === null ||
      formData.get("email").trim() === "" ||
      !emailRegex.test(formData.get("email"))
    ) {
      if (formData.get("email").trim() === "") {
        serError((preError) => ({
          ...preError,
          email: "Email address is required",
        }));
      } else {
        serError((prevError) => ({
          ...prevError,
          email: "Enter a valid email address",
        }));
      }
      return false;
    } else if (
      formData.get("gender") === "undefined" ||
      formData.get("gender") === null ||
      formData.get("gender").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        gender: "Please select any one",
      }));
      return false;
    } else if (!ADDEDITDATA?.image === "" && formData.get("image") === "null") {
      serError((preError) => ({
        ...preError,
        image: "Image is required",
      }));
      return false;
    } else {

      return true;

    }
  }; //End

  const handleDelete = async () => {
    try {
      const options = {
        url: `accounts/users/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data deleted Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/users/list");
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    document.title = `Frisbee Super Admin || Add/Edit User`;
  }, []);
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">Edit User</h5>
          </div>
          <Link to="/users/list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          {/* <div className="card-header py-3 invetory_title_div">
            <div>
              <h6 className="m-0 font-weight-bold text-black">Edite User</h6>
            </div>
            {ADDEDITDATA?._id && (
              <div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  Delete Data
                </button>
              </div>
            )}
          </div> */}
          <div className="card-body">
            <div className="responsive">
              <form method="post">
                <div className="form-group vendor_Add_edit_data">
                  <label htmlFor="name" className="all_top_and_bottom_for_input_text">
                    <span style={{ color: "red" }}>*</span>Full Name :{" "}
                  </label>
                  <input
                    title="Enter your name."
                    type="text"
                    name="name"
                    className="form-control store_input_field"
                    id="name"
                    value={ADDEDITDATA?.name}
                    onChange={handleChange}
                  />
                  {error.name ? (
                    <p style={{ color: "red" }}>{error.name}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group vendor_Add_edit_data">
                  <label htmlFor="email" className="all_top_and_bottom_for_input_text">
                    <span style={{ color: "red" }}>*</span>Email Address :
                  </label>
                  <input
                    title="Enter your email address."
                    type="email"
                    name="email"
                    className="form-control store_input_field"
                    id="email"
                    value={ADDEDITDATA?.email}
                    // readOnly={ADDEDITDATA?.email?true:false}
                    onChange={handleChange}
                  />
                  {error.email ? (
                    <p style={{ color: "red" }}>{error.email}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group vendor_Add_edit_data">
                  <label htmlFor="phone" className="all_top_and_bottom_for_input_text">
                    <span style={{ color: "red" }}>*</span>Phone Number :
                  </label>
                  <input
                    title="Enter your phone number."
                    type="number"
                    name="phone"
                    className="form-control store_input_field"
                    id="phone"
                    value={ADDEDITDATA?.phone}
                    readOnly={ADDEDITDATA?.phone ? true : false}
                  />
                  {error.phone ? (
                    <p style={{ color: "red" }}>{error.phone}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="from-group">
                  <label htmlFor="phone" className="all_top_and_bottom_for_input_text">
                    <span style={{ color: "red" }}>*</span>Gender :
                  </label>
                  <Flex vertical gap="middle" className="">
                    <div
                      className="verndor_dashboard_add_edit_button"
                      defaultValue="Male"
                      name="gender"
                      id="gender"
                      size="large"
                      onChange={onCheckedChange}
                    >
                      <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                          <div className="vendor_dashbaord_gender_main_section">
                            <label htmlFor="male">Male</label>
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              value="Male"
                              className="form-check-input"
                              checked={
                                ADDEDITDATA?.gender === "Male" ? true : false
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                          <div className="vendor_dashbaord_gender_main_section">
                            <label htmlFor="female">Female</label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="Female"
                              className="form-check-input"
                              checked={
                                ADDEDITDATA?.gender === "Female" ? true : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Radio.Group> */}
                  </Flex>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="all_top_and_bottom_for_input_text">Images</label>
                  <Dragger
                    className="about_us_dragger_file"
                    maxCount="1"
                    listType="picture"
                    onChange={handleImageChnage}
                    beforeUpload={beforeUpload}
                  >
                    {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                    {/* <p className="ant-upload-hint">
                      Support for a single. Only accept jpeg/jpg/png format.
                    </p> */}
                  </Dragger>
                  <p style={{ color: "blue" }} className="all_top_and_bottom_for_input_text">
                    *Note: Please choose an image of JPEG/JPG/PNG format file.
                  </p>
                  {error.image ? (
                    <p style={{ color: "red" }}>{error.image}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListEditPage;
