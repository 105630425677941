import React, { useEffect, useState, useRef } from "react";
import Top_navbar from "../../DashBaord/Top_navbar";
import { ASSETS_BASE_URL } from "../../../config/constants";
import { FaPlusSquare } from "react-icons/fa";
import { Select, Typography } from "antd";
import { TbCategory } from "react-icons/tb";
import { notification } from "antd";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import moment from "moment";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { addEditData } from "../../../controllers/cms/emailTemplets";
import { Link, useLocation, useNavigate } from "react-router-dom";

function CategoriesProductList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CategoriesProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Treats() {
  /********************** Integratin Stare Here **********************/
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [GETDATA, SETGETDATA] = useState(state ? state : []);
  const [DESCRIPTION, setDescription] = useState(GETDATA?.templet || '');
  const [errors, setErrors] = useState("");
  // console.log('ADDEDITDATA',ADDEDITDATA);
  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    if(GETDATA?._id){
      form.append('editId',GETDATA?._id);
    }
    form.append('templet',DESCRIPTION);
    const rawData = {};
    const isValidate = await validateFormData(form);
    if(isValidate){
      form.forEach((value, key) => {
        rawData[key] = value;
      });
      const res = await addEditData(rawData);
      if(res.status === true){
        notification.open({
          message: "Success",
          description: " Data saved Successfully.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
        SETGETDATA('');
        setDescription();
        navigate('/cms/email-templates');
      }else{
        notification.open({
          message: "Error",
          description: res?.message || "Please try after some time",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="red" />,
        });
      }
    }
  };

  const validateFormData = async (formData) => {
    try {
      if (
        formData.get("type") === "undefined" ||
        formData.get("type") === null ||
        formData.get("type").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          type: "Name is required",
        }));
        return false;
       } else if (
          formData.get("subject") === "undefined" ||
          formData.get("subject") === null ||
          formData.get("subject").trim() === ""
        ) {
          setErrors((preError) => ({
            ...preError,
            subject: "Name is required",
          }));
          return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      SETGETDATA((pre) => ({
        ...pre,
        [name]: value,
      }));
    } catch (error) {}
  };
  useEffect(()=>{
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  },[])
  /********************** Integratin End Here **********************/
  return (
    <div className="container-fluid" ref={targetRef}>
      <Top_navbar title={`${GETDATA?._id?'Edit':'Add'} Email Template`}></Top_navbar>
      <div className="inventory_tabs_main_div">
        <Box sx={{ width: "100%" }}>
          <div>
            <div id="content-wrapper" className="d-flex flex-column">
              <div className="card shadow mb-4">
                <div className="card-body pt-0">
                  <div className="table-responsive term_and_condation_main_section">
                    <form method="post"  onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                          <label for="type" className="categories_open_popup_label_name all_lebel_margin_top_main_div" > <span style={{ color: "red" }}>*</span> Type</label>
                          <select className="form-control categories_select_tg_enter_product" name='type' id='type'>
                            <option value='order-booking-confirm' selected={GETDATA?.type === 'order-booking-confirm'?true:false}>Order Booking Confirm</option>
                            <option value='order-dispatched' selected={GETDATA?.type === 'order-dispatched'?true:false}>Order Dispatched</option>

                            <option value='grooming-booking-confirm' selected={GETDATA?.type === 'grooming-booking-confirm'?true:false}>Grooming Booking Confirm</option>
                            <option value='grooming-service-reminder' selected={GETDATA?.type === 'grooming-service-reminder'?true:false}>Grooming Service Reminder</option>
                            <option value='grooming-service-completed' selected={GETDATA?.type === 'grooming-service-completed'?true:false}>Grooming Service Completed</option>
                            <option value='grooming-service-accepted' selected={GETDATA?.type === 'grooming-service-accepted'?true:false}>Grooming Service Accepted</option>
                            <option value='grooming-service-rescheduled' selected={GETDATA?.type === 'grooming-service-rescheduled'?true:false}>Grooming Service Rescheduled</option>
                            <option value='grooming-service-cancelled' selected={GETDATA?.type === 'grooming-service-cancelled'?true:false}>Grooming Service Cancelled</option>

                            <option value='boarding-booking-confirm' selected={GETDATA?.type === 'boarding-booking-confirm'?true:false}>Boarding Booking Confirm</option>
                            <option value='boarding-service-reminder' selected={GETDATA?.type === 'boarding-service-reminder'?true:false}>Boarding Service Reminder</option>
                            <option value='boarding-service-completed' selected={GETDATA?.type === 'boarding-service-completed'?true:false}>Boarding Service Completed</option>
                            <option value='boarding-service-accepted' selected={GETDATA?.type === 'boarding-service-accepted'?true:false}>Boarding Service Accepted</option>
                            <option value='boarding-service-rescheduled' selected={GETDATA?.type === 'boarding-service-rescheduled'?true:false}>Boarding Service Rescheduled</option>
                            <option value='boarding-service-cancelled' selected={GETDATA?.type === 'boarding-service-cancelled'?true:false}>Boarding Service Cancelled</option>
                            <option value='boarding-payment-request' selected={GETDATA?.type === 'boarding-payment-request'?true:false}>Boarding Payment Request</option>

                            <option value='day-care-booking-confirm' selected={GETDATA?.type === 'day-care-booking-confirm'?true:false}>Day Care Booking Confirm</option>
                            <option value='day-care-service-reminder' selected={GETDATA?.type === 'day-care-service-reminder'?true:false}>Day Care Service Reminder</option>
                            <option value='day-care-service-completed' selected={GETDATA?.type === 'day-care-service-completed'?true:false}>Day Care Service Completed</option>
                            <option value='day-care-service-accepted' selected={GETDATA?.type === 'day-care-service-accepted'?true:false}>Day Care Service Accepted</option>
                            <option value='day-care-service-rescheduled' selected={GETDATA?.type === 'day-care-service-rescheduled'?true:false}>Day Care Service Rescheduled</option>
                            <option value='day-care-service-cancelled' selected={GETDATA?.type === 'day-care-service-cancelled'?true:false}>Day Care Service Cancelled</option>

                            <option value='veterinary-booking-confirm' selected={GETDATA?.type === 'veterinary-booking-confirm'?true:false}>Veterinary Booking Confirm</option>
                            <option value='veterinary-service-reminder' selected={GETDATA?.type === 'veterinary-service-reminder'?true:false}>Veterinary Service Reminder</option>
                            <option value='veterinary-service-completed' selected={GETDATA?.type === 'veterinary-service-completed'?true:false}>Veterinary Service Completed</option>
                            <option value='veterinary-service-accepted' selected={GETDATA?.type === 'veterinary-service-accepted'?true:false}>Veterinary Service Accepted</option>
                            <option value='veterinary-service-rescheduled' selected={GETDATA?.type === 'veterinary-service-rescheduled'?true:false}>Veterinary Service Rescheduled</option>
                            <option value='veterinary-service-cancelled' selected={GETDATA?.type === 'veterinary-service-cancelled'?true:false}>Veterinary Service Cancelled</option>

                            <option value='training-booking-confirm' selected={GETDATA?.type === 'training-booking-confirm'?true:false}>Training Booking Confirm</option>
                            <option value='training-service-reminder' selected={GETDATA?.type === 'training-service-reminder'?true:false}>Training Service Reminder</option>
                            <option value='training-service-completed' selected={GETDATA?.type === 'training-service-completed'?true:false}>Training Service Completed</option>
                            <option value='training-service-accepted' selected={GETDATA?.type === 'training-service-accepted'?true:false}>Training Service Accepted</option>
                            <option value='training-service-rescheduled' selected={GETDATA?.type === 'training-service-rescheduled'?true:false}>Training Service Rescheduled</option>
                            <option value='training-service-cancelled' selected={GETDATA?.type === 'training-service-cancelled'?true:false}>Training Service Cancelled</option>
                          </select>
                          {errors.type ? ( <p style={{ color: "red" }}> {errors.type} </p> ) : ( "" )}
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                          <label for="subject" className="categories_open_popup_label_name all_lebel_margin_top_main_div" > <span style={{ color: "red" }}>*</span> Subject </label>
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={GETDATA?.subject}
                            className="form-control categories_select_tg_enter_product"
                            onChange={handleChange}
                          />
                          {errors.subject ? ( <p style={{ color: "red" }}> {errors.subject} </p> ) : ( "" )}
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                          <label
                            for="title"
                            className="categories_open_popup_label_name all_lebel_margin_top_main_div"
                          >
                            Message{" "}
                          </label>
                        </div>
                        <div className="term_and_condation_react_quilllll">
                          <ReactQuill
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, false] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],
                                [
                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },
                                ],
                                ["link", "image"],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "header",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "list",
                              "bullet",
                              "indent",
                              "link",
                              "image",
                            ]}
                            value={DESCRIPTION || ""}
                            onChange={setDescription}
                          />
                        </div>
                            <br/>
                        <div>
                          <button type="submit" class="btn btn-danger all_save_btn_btn_btn" >
                            <span className="request_new_categories"> {GETDATA?._id?'Save Changes':'Create'}</span>
                          </button> 
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
